import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper'

import Layout from "../components/layout";
import SEO from "../components/seo";
import Button from "../components/buttons/button";

import calendar from "../images/drivers/calendar.png";
import GoogleAuth from "../googleAuth";

function DateTimePage() {
  return (
    <Layout>
      <SEO
        keywords={[`Nubee`, `driving school`, `learners`, `artificial intelligence`]}
        title="Drivers"
      />

      <section className="md:p-0 text-left pt-28 md:pt-20">
        <div className="px-6 md:max-w-7xl mx-auto">
          <div className="md:pr-2">
          <a href="/drivers-instructor-selection">← Go Back</a>
            <div>
              <h1 className="inline-block w-11/12 md:w-3/4 my-4 text-5xl md:text-6xl font-bold text-black font-heading">
                Have a look at your instructors calendar.
              </h1>
              <p className="py-8 text-black font-body max-w-xl">Select a date and time that fits your diary.</p>
            </div>
          </div>
        </div>
      </section>

      <section className="mt-5 md:pt-10">
        <div className="flex flex-col md:flex-row gap-5 gap:md:gap-32 items-center px-6 md:max-w-7xl mx-auto">
          <img className="object-contain w-3/4 md:w-2/5 mx-auto" src={calendar}/>
          <div className="text-center border-2 border-black order-first md:order-last">
            <h1 className="text-3xl md:text-6xl font-bold text-black font-heading p-5 md:p-32">
              Calendar widget goes here
            </h1>
          </div>
        </div>
      </section>

      <section className="px-6 md:pr-16 py-3 md:pt-5">
        <div className="text-right">
        {/*<Button title="Continue"></Button>*/}
            <GoogleAuth />
        </div>
      </section>
    </Layout>
  );
}

export default DateTimePage;
