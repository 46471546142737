import React from 'react';
import { GoogleLogin } from '@react-oauth/google';
import { navigate } from 'gatsby';
import { GoogleOAuthProvider } from '@react-oauth/google';

const GoogleAuth = () => {
    const responseGoogle = (response) => {
        if (response.error) {
            console.log(response.error);
        } else {
            // Handle successful login
            // You can use the response.accessToken and response.profileObj
            // to authenticate the user and get their profile information
            navigate("https://app.nubee.co.za/#/")
        }
    };

    return (
        <GoogleOAuthProvider clientId="693056435673-4o3jvs3pe9fpamn1m5bnksara734pc79.apps.googleusercontent.com">
            <GoogleLogin
                clientId="693056435673-4o3jvs3pe9fpamn1m5bnksara734pc79.apps.googleusercontent.com"
                buttonText="Login with Google"
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                cookiePolicy={'single_host_origin'}
                ux_mode="redirect"
            />
        </GoogleOAuthProvider>

    );
};

export default GoogleAuth;

